module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-layout@4.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@9.23.0__encoding@0.1.13__rnrcfd6wxkjo54taymxfdff7cu/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.1_babel-eslint@10.1.0_eslint@9.23.0__encoding@0.1.13_react-dom@18.3.1_react@18.3._5mobmtki336msluocmhnuuob3u/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
